import React from "react"
import {Link} from "gatsby"
import * as aboutstyles from "../../styles/pages/about.module.css"
import * as styles from "./style.module.css"

import imgvideoholder from "../../images/videoholder.png"
import menulist from "../../content/footermenulist.json"

const AboutPageHeader = ({ activemenu }) => {
	const videotitle = "Soar";
	const videoid = "QrhBM91mU6A";
	const srcurl = "https://www.youtube.com/embed/?rel=0"+videoid+ "&playlist="+videoid+"&controls=0&disablekb=1&autoplay=0&loop=1";
	//const srcurl = "https://www.youtube.com/embed/?rel=0"+videoid+ "&playlist="+videoid+"&controls=0&disablekb=1&autoplay=1&mute=1&loop=1";

	return (<>
		<div itemscope="" itemprop="VideoObject" itemtype="https://schema.org/VideoObject" className={styles.videoholder}>
			<meta itemprop="name" content={videotitle} />
			<meta itemprop="description" content={"BOYSEN is a known leader in the paint industry. We are not just the largest paint manufacturer in the country, we are also one of the most trusted. We continue in our efforts to provide our consumers with the best products in the market. In the tradition of excellence, we at BOYSEN always work to provide quality and innovation. As a socially responsible brand, we are pioneers in the production of eco – friendly paints, proving our commitment to the environment and public health."} />
			<meta itemprop="uploadDate" content={"2024-01-02T08:00:00+08:00"} />
			<meta itemprop="embedUrl" content={"https://www.youtube.com/embed/"+videoid} />
			<meta itemprop="thumbnailUrl" content={"https://img.youtube.com/vi/"+videoid+"/maxresdefault.jpg"} />
			<img className={styles.videoholder} src={imgvideoholder} alt="video" />
			<iframe frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" title={videotitle} className={styles.videoholder} width="100%" height="100%" src={srcurl}></iframe>
		</div>
		<div className={"text-centered font-size-tiny "+styles.videotitle}>
			"Soar" - Pacific Paint (BOYSEN) Philippines, Inc. Corporate AVP
		</div>
		<div className={"text-centered "+styles.whoweare+" "+aboutstyles.content}>
			<div className={"font-size-large "+aboutstyles.contenttitle}>WHO WE ARE</div>
			<div className={aboutstyles.contenttext}>
				Pacific Paint (<span className="nobr">BOYSEN<span className={"registeredicon"}>&reg;</span></span>) Philippines, Inc. is the leading paint manufacturer<span className={styles.whowearelinebreak}>in the country, supplying both architectural paints and construction chemicals</span>to local and international markets.
			</div>
			<a className={"font-size-small "+aboutstyles.contentlink} target="_blank" rel="noreferrer" href="https://files.boysencdn.com/boysen/site/files/about/Pacific Paint (BOYSEN) Philippines, Inc - Company Profile.pdf" title="Download our company profile">Download our company profile</a>
		</div>
		<div className={styles.linksection}>
			{menulist[0].subitems.map((entry,idx)=> {
				var title = entry.title;
				if (idx === 0) {
					title = "About Us";
				}
				return <Link className={(idx>0?styles.linksectionitemdivider+" ":"")+"font-size-medium font-style-bold" + (activemenu===entry.url?" "+styles.linkactive:"")} to={"/"+entry.url} title={title}>{title}</Link>
			})}
		</div>
	</>)
}

export default AboutPageHeader
