// extracted by mini-css-extract-plugin
export var mainholder = "history-module--mainholder--51a5d";
export var maincontent = "history-module--maincontent--91451";
export var maincontentyear = "history-module--maincontentyear--c0b54";
export var maincontentdata = "history-module--maincontentdata--3e974";
export var mainbg = "history-module--mainbg--e826d";
export var mainbgright = "history-module--mainbgright--b712c";
export var mainbgcenter = "history-module--mainbgcenter--922a5";
export var mainbgleft = "history-module--mainbgleft--4967d";
export var mainbgleft1 = "history-module--mainbgleft1--0b871";
export var mainbgleft2 = "history-module--mainbgleft2--2e481";
export var mainbgright1 = "history-module--mainbgright1--a5d36";
export var mainbgright2 = "history-module--mainbgright2--1d407";
export var mobileappcontentarea = "history-module--mobileappcontentarea--73fbf";
export var maincontentrow = "history-module--maincontentrow--8d3d4";