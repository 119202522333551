import React from "react"

import Layout from "../../components/layout"
import AboutPageHeader from "../../components/aboutpageheader"

import * as styles from "../../styles/pages/about/history.module.css"

import { StaticImage } from "gatsby-plugin-image"

import historylist from "../../content/about/history.json"

import imgtimebar from "../../images/about/history/timelinebar.png"
import imgtimebarshort from "../../images/about/history/timelinebar-short.png"

const img01 = "../../images/about/history/01.jpg"
const img02 = "../../images/about/history/02.jpg"
const img03 = "../../images/about/history/03.jpg"

const img11 = "../../images/about/history/11.jpg"
const img12 = "../../images/about/history/12.jpg"


const AboutHistoryPage = () => (
	<Layout activePath='about/history' pageTitle="Historical Timeline" pageDescription="History of Pacific Paint (BOYSEN) Philippines, Inc.">
		<AboutPageHeader activemenu="about/history" />
		<div className={styles.mainholder}>
			<div className={styles.mainbg}>
				<div className={styles.mainbgleft}>
					<StaticImage layout={"fullWidth"}  src={img01} alt={"Bird"} />
					<div className={styles.mainbgleft1}></div>
					<StaticImage layout={"fullWidth"}  src={img02} alt={"Bird"} />
					<div className={styles.mainbgleft2}></div>
					<StaticImage layout={"fullWidth"}  src={img03} alt={"Bird"} />
				</div>
				<div className={styles.mainbgcenter}>&nbsp;</div>
				<div className={styles.mainbgright}>
					<div className={styles.mainbgright1}></div>
					<StaticImage layout={"fullWidth"}  src={img11} alt={"Bird"} />
					<div className={styles.mainbgright2}></div>
					<StaticImage layout={"fullWidth"}  src={img12} alt={"Bird"} />
				</div>
			</div>
			<div className={styles.maincontent}>
				{historylist.map((entry, idx)=> {
					return <div className={styles.maincontentrow}>
						<div className={styles.maincontentyear+" starkregularfont"}>
							<h2 className={"starkregularfont"}>{entry.year}</h2>
							{(idx < historylist.length-1) && <>
								{idx < historylist.length-3 ? <>
									<img src={imgtimebar} className={"displayinline-desktop-only"} alt="|" />
									<img src={imgtimebarshort} className={"displayinline-mobile-only"} alt="|" />
								</>:<>
									<img src={imgtimebar} alt="|" />
								</>}
							</>}
						</div>
						<div className={styles.maincontentdata}>
							{entry.content.map((subentry, subidx)=> {
								return <div dangerouslySetInnerHTML={{__html:subentry.replace(/\n/g, "<br/>\n")}} />
							})}
						</div>
					</div>
				})}
			</div>
		</div>
	</Layout>
)

export default AboutHistoryPage
